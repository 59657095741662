<script>
import Layout from "../../layouts/auth";

import { mapActions } from "vuex";
import Swal from 'sweetalert2'
import appConfig from "@/app.config";
import { required, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

/**
 * Login component
 */
export default {
	setup() {
		return { v$: useVuelidate() };
	},
	page: {
		title: "Login",
		meta: [
			{
				name: "description",
				content: appConfig.description,
			},
		],
	},
	components: {
		Layout,
	},
	data() {
		return {
			username: "",
			password: "",
			submitted: false,
			authError: null,
			tryingToLogIn: false,
			isAuthError: false,
			change_password_modal:false,
			selected: 'login',
			pl:{
				username: null,
				email_address: null,
				contact_number: null,
				address: null,
				birthday: null,
				password: null,
				confirm_password: null,
				role: null,
				first_name: null,
				last_name: null,
			},
			new_users:[
				"Lucina",
				"Michelle",
				"Lemmy",
				"Monro",
				"Olivier",
				"Dagny",
				"Muffin",
				"Gisele",
				"Pall",
				"Patsy",
				"Berenice",
				"Rorie",
				"Shayne",
				"Benny",
				"Garrik",
				"Carce",
				"Analise",
				"Caye",
				"Paul",
				"Zsa",
				"Rana",
				"Gerard",
				"Jodi",
				"Rodrigo",
				"Averell",
				"Jaclin",
				"Sinclair",
				"Jarret",
				"Cris",
				"Elisha",
				"Mil",
				"Stevena",
				"Bettye",
				"Silvano",
				"Dorotea",
				"Tamiko",
				"Caesar",
				"Tedd",
				"Dalton",
				"Marsh",
				"Gearard",
				"Philly",
				"Mildred",
				"Audrie",
				"Rudolph",
				"Even",
				"Ailene",
				"Marian",
				"Sutton",
				"Harv",
				"Rowland",
				"Kienan",
				"Beauregard",
				"Modesty",
				"Glenda",
				"Adlai",
				"Evelyn",
				"Marie",
				"Mortie",
				"Lorna",
				"Fleming",
			],
			newLoggedin: '',
			showPassword: false,
			forgot_password_modal: false,
		};
	},
	validations: {
		username: {
			required: helpers.withMessage("username is required", required),
		},
		password: {
			required: helpers.withMessage("Password is required", required),
		},
	},
	computed: {
		notification() {
			return this.$store ? this.$store.state.notification : null;
		},
	},
	methods: {
        ...mapActions('auth', {
            authAdmin: 'loginSeller',
            newUser: 'register',
        }),
		...mapActions("auth", {
			getAuthProfile: "getProfile",
		}),
		async profileInterval() {
			await this.getAuthProfile();
		},
		gotoRegister(){
			this.$router.push({path: "/register"});
		},
		async tryToLogIn() {
			this.submitted = true;
			this.v$.$touch();

			if (this.v$.$invalid) {
				return;
			} else {
                var fd = new FormData();
                fd.append('username', this.username);
                fd.append('password', this.password);
                const res = await this.authAdmin(fd);
                if (res.status === 'success' || res.status === 200) {
					this.profileInterval();
                    this.$router.replace({ path: '/' }).catch(() => {});
                }else{
                    Swal.fire({
                        html: res,
                        type: "error",
                        icon: "error",
                        padding: "2em",
                    });
				}
			}
		},
        async registerUser(){
			var pl = {
				username : this.pl.username,
				email_address : this.pl.email_address,
				contact_number : this.pl.contact_number,
				address : this.pl.address,
				birthday : this.pl.birthday,
				password : this.pl.password,
				confirm_password : this.pl.confirm_password,
				role : this.pl.role,
				first_name : this.pl.first_name,
				last_name : this.pl.last_name,
			}
            const res = await this.newUser(pl);
            if (res.status == 200|| res.status == 'success') {
                Swal.fire({
                    title: "Success",
                    html: `Posted.`,
                    type: "success",
                    icon: "success",
                    padding: "2em",
                });
                this.initList(1);
                this.post_modal = false;
            }else{
                Swal.fire({
                    html: res,
                    type: "error",
                    icon: "error",
                    padding: "2em",
                });
            }
        },
        toggleShow() {
            this.showPassword = !this.showPassword;
        },
		showRandomAnnouncement() {
			const randomTime = `${Math.floor(Math.random() * 5)}000`;
			this.newLoggedin = this.getRandomAnnouncement();
				setTimeout(() => {
					this.newLoggedin = '';
				}, parseInt(randomTime));
				setTimeout(this.showRandomAnnouncement, 8000);
				},
				getRandomAnnouncement() {
				const randomIndex = Math.floor(Math.random() * this.new_users.length);
			return this.new_users[randomIndex];
		}
	},
	mounted() {
		this.showRandomAnnouncement();
	},
};
</script>

<template>
	<Layout>
		<div class="row justify-content-center">
			<div class="col-md-8 col-lg-6 col-xl-5">
				<div class="card overflow-hidden">
					<div class="bg-soft bg-warning">
						<div class="row">
							<div class="new-registered">
								<div v-if="newLoggedin">
									<span>Just now:</span>
									<span class="text-danger mx-2">{{newLoggedin }}</span>
									<span class="text-muted">logged in</span>
								</div>
							</div>
							<div class="col-9">
								<div class="text-danger p-4">
								<h5 class="text-danger">Welcome Back !</h5>
								<p>Sign in to explore Kio88 website.</p>
								</div>
							</div>
							<div class="col-3 align-self-end">
								<img
									src="@/assets/images/profile-img.png"
									alt
									class="img-fluid"
								/>
							</div>
						</div>
					</div>
					<div class="card-body pt-0">
						<div>
							<router-link to="/">
								<div class="avatar-md profile-user-wid mb-4">
									<span
										class="avatar-title rounded-circle bg-white"
									>
										<img src="@/assets/images/bag.jpg" alt height="34" />
										<!-- <i class="bx bx-log-in-circle text-white fs-1"></i> -->
									</span>
								</div>
							</router-link>
						</div>
						<b-alert
							v-model="isAuthError"
							variant="danger"
							class="mt-3"
							dismissible
							>{{ authError }}</b-alert
						>
						<div
							v-if="notification.message"
							:class="'alert ' + notification.type"
						>
							{{ notification.message }}
						</div>
						<b-form class="p-2" @submit.prevent="tryToLogIn" v-if="selected == 'login'">
							<b-form-group
								class="mb-3"
								id="input-group-1"
								label="Username"
								label-for="input-1"
							>
								<b-form-input
									id="input-1"
									v-model="username"
									type="text"
									placeholder="Enter username"
									:class="{
										'is-invalid':
											submitted && v$.username.$error,
									}"
								></b-form-input>
								<div
									v-for="(item, index) in v$.username.$errors"
									:key="index"
									class="invalid-feedback"
								>
									<span v-if="item.$message">{{
										item.$message
									}}</span>
								</div>
							</b-form-group>
							<!-- <b-form-group
								class="mb-3"
								id="input-group-2"
								label="Password"
								label-for="input-2"
							>
								<b-form-input
									id="input-2"
									v-model="password"
									type="password"
									placeholder="Enter password"
									:class="{
										'is-invalid':
											submitted && v$.password.$error,
									}"
								></b-form-input>
								<div
									v-if="submitted && v$.password.$error"
									class="invalid-feedback"
								>
									<span
										v-if="v$.password.required.$message"
										>{{
											v$.password.required.$message
										}}</span
									>
								</div>
							</b-form-group> -->
							<b-form-group class="mb-3" id="input-group-2" label="Password" label-for="password">
                                <div class="d-flex">
                                    <b-form-input v-if="showPassword" class="input eye-width" id="password"
                                        v-model="password" type="text" placeholder="Enter your password" :class="{
                                            'is-invalid':
                                                submitted && v$.password.$error,
                                        }">
                                    </b-form-input>
                                    <b-form-input v-else class="input eye-width" id="password" v-model="password"
                                        type="password" placeholder="Enter your password" :class="{
                                            'is-invalid':
                                                submitted && v$.password.$error,
                                        }">
                                    </b-form-input>
									<span @click="toggleShow"  class="icon is-small is-right eye-btn">
										<i class="fas"
											:class="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }"></i>
									</span>
                                </div>
                            </b-form-group>
							<!-- <b-form-checkbox
								class="form-check me-2 mt-0"
								id="customControlInline"
								name="checkbox-1"
								value="accepted"
								unchecked-value="not_accepted"
								>Remember me
							</b-form-checkbox> -->
							<div class="mt-3 d-grid">
								<span @click="forgot_password_modal = true" style="cursor: pointer;">Forgot Password?</span>
							</div>
							<div class="mt-3 d-grid">
								<b-button
									type="submit"
									variant="warning"
									class="btn-block"
									>Log In</b-button
								>
							</div>
							<div class="mt-3 d-grid">
								<b-button
									variant="warning"
									class="btn-block btn-light"
									@click="gotoRegister()"
									>Sign up</b-button
								>
							</div>
						</b-form>
						<b-form class="p-2" @submit.prevent="registerUser" v-if="selected == 'register'">
							<b-form-group
								class="mb-3"
								id="input-group-1"
								label="Username"
								label-for="input-1"
							>
								<b-form-input
									id="input-1"
									v-model="pl.username"
									type="text"
									placeholder="Enter username"
								></b-form-input>
							</b-form-group>
							<b-form-group
								class="mb-3"
								id="input-group-1"
								label="Email address"
								label-for="input-1"
							>
								<b-form-input
									id="input-1"
									v-model="pl.email_address"
									type="email"
									placeholder="Enter email address"
								></b-form-input>
							</b-form-group>
							<b-form-group
								class="mb-3"
								id="input-group-1"
								label="Contact Number"
								label-for="input-1"
							>
								<b-form-input
									id="input-1"
									v-model="pl.contact_number"
									type="email"
									placeholder="Enter contact number"
								></b-form-input>
							</b-form-group>
							<b-form-group
								class="mb-3"
								id="input-group-1"
								label="Address"
								label-for="input-1"
							>
								<b-form-input
									id="input-1"
									v-model="pl.address"
									type="email"
									placeholder="Enter address"
								></b-form-input>
							</b-form-group>
							<b-form-group
								class="mb-3"
								id="input-group-1"
								label="Birthday"
								label-for="input-1"
							>
								<b-form-input
									id="input-1"
									v-model="pl.birthday"
									type="date"
								></b-form-input>
							</b-form-group>
							<b-form-group
								class="mb-3"
								id="input-group-1"
								label="First Name"
								label-for="input-1"
							>
								<b-form-input
									id="input-1"
									v-model="pl.first_name"
									type="text"
									placeholder="Enter First Name"
								></b-form-input>
							</b-form-group>
							<b-form-group
								class="mb-3"
								id="input-group-1"
								label="Last Name"
								label-for="input-1"
							>
								<b-form-input
									id="input-1"
									v-model="pl.last_name"
									type="text"
									placeholder="Enter Last Name"
								></b-form-input>
							</b-form-group>

							<b-form-group
								class="mb-3"
								id="input-group-2"
								label="Password"
								label-for="input-2"
							>
								<b-form-input
									id="input-2"
									v-model="pl.password"
									type="password"
									placeholder="Enter password"
								></b-form-input>
							</b-form-group>
							<b-form-group
								class="mb-3"
								id="input-group-2"
								label="Confirm Password"
								label-for="input-2"
							>
								<b-form-input
									id="input-2"
									v-model="pl.confirm_password"
									type="password"
									placeholder="Confirm password"
								></b-form-input>
							</b-form-group>
							<b-form-checkbox
								class="form-check me-2 mt-0"
								id="customControlInline"
								name="checkbox-1"
								value="accepted"
								unchecked-value="not_accepted"
								>Remember me
							</b-form-checkbox>
							<div class="mt-3 d-grid">
								<b-button
									type="submit"
									variant="warning"
									class="btn-block"
									>Register</b-button
								>
							</div>
						</b-form>
					</div>
					<!-- end card-body -->
				</div>
				<!-- end row -->
			</div>
			<!-- MODALS -->
			<b-modal 
				v-model="forgot_password_modal" 
				no-close-on-backdrop
				hide-footer
				size="md"
				title="Forgot Password" 
				centered
				>
				<h5>Kindly contact our admin and we will redirect you after clicking this link.</h5>
				<div class="col-12 mt-3">
					<a class="btn btn-info btn-sm w-100" href="https://t.me/+3oS0jI5nqCo0NDAx" target="_blank" @click="forgot_password_modal = false">Redirect</a>
				</div>
			</b-modal>
			<!-- end col -->
		</div>
		<!-- end row -->
	</Layout>
</template>
<style scoped>
.new-registered{
	font-size: 20px;
	height: 70px;
	background-color: #f9f9f9;
	text-align: center;
	align-content: center;
}
.eye-width {
    border-radius: 0px !important;
}

.eye-btn {
    background-color: transparent;
    border: none;
    border-radius: 0px !important;
	position: absolute;
	right: 40px;
	margin-top: 8px;
	color: rgb(95, 95, 95) !important;
}
</style>